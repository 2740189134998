import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { getToken } from './token';

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_BACKEND_HOST + '/graphql',
});

const authLink = setContext((_, { headers }) => {

    const token = getToken();

    return {
        headers: {
            ...headers,
            ...token && { Authorization: `Bearer ${token}`}
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
    }
});


export default client;