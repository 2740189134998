import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from 'styled-components';
import { DashboardTemplate } from "../../templates";
import { EventNew, EventUpdate } from '../../organisms';
import useAuth from "../../../utils/useAuth";

function DashboardLayout() {

    return (
        <Container>
            <Switch>
                <Route exact path="/dashboard" component={DashboardTemplate} />
                <Route exact path="/dashboard/novo" component={EventNew} />
                <Route exact path="/dashboard/editar/:id" component={EventUpdate} />
                <Redirect path="*" to="/dashboard" />
            </Switch>
        </Container>
    )

}

export default useAuth(DashboardLayout)

const Container = styled.div`
  position: relative;
  display:flex;
  flex-flow: column;
  height: 100vh;
  max-height: 100vh;
  background-color: #EEEEEE;
`;