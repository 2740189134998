import styled, { css } from 'styled-components';

const Container = styled.div`
  ${({ sm }) => sm && css`
    width: 962px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1200px) {
      width: calc(100% - 180px);
      margin-left: 90px;
      margin-right: 90px;
    }
    @media screen and (max-width: 768px) {
      width: calc(100% - 80px);
      margin-left: 40px;
      margin-right: 40px;
    }
    @media screen and (max-width: 578px) {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;
    }
  `}
  ${({ md }) => md && css`
    width: 1156px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1200px) {
      width: calc(100% - 180px);
      margin-left: 90px;
      margin-right: 90px;
    }
    @media screen and (max-width: 768px) {
      width: calc(100% - 80px);
      margin-left: 40px;
      margin-right: 40px;
    }
    @media screen and (max-width: 578px) {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;
    }
  `}
  ${({ lg }) => lg && css`
    width: 1352px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1440px) {
      width: 1164px;
    }
    @media screen and (max-width: 1200px) {
      width: calc(100% - 180px);
      margin-left: 90px;
      margin-right: 90px;
    }
    @media screen and (max-width: 768px) {
      width: calc(100% - 80px);
      margin-left: 40px;
      margin-right: 40px;
    }
    @media screen and (max-width: 578px) {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;
    }
  `}
  ${({ left }) => left && css`
    width: 1680px;
    margin-left: auto;
    @media screen and (max-width: 1980px) {
      width: calc(100% - 220px);
      margin-left: 220px;
    }
    @media screen and (max-width: 1440px) {
      width: 1164px;
      margin-left: auto;
    }
    @media screen and (max-width: 1200px) {
      width: calc(100% - 90px);
      margin-left: 90px;
    }
    @media screen and (max-width: 768px) {
      width: calc(100% - 30px);
      margin-left: 30px;
    }
    @media screen and (max-width: 578px) {
      width: calc(100% - 20px);
      margin-left: 20px;
    }
  `}
`;

export default Container;