import React from "react";

export default function Text(
  {
    singleLine,
    pointer,
    mt,
    mb,
    ml,
    mr,
    pt,
    pb,
    pl,
    pr,
    width,
    size,
    transform,
    underline,
    align,
    // styling
    regular,
    bold,
    semibold,
    medium,
    weight,
    light,
    center,
    right,
    spacing, // letter-spacing
    height, // line-height
    // colors
    color,
    style,
    children,
    ...props
  }
) {

  const textStyles = [
    styles.text,
    singleLine && { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" },
    pointer && { cursor: "pointer" },
    mt && { marginTop: mt },
    mb && { marginBottom: mb },
    ml && { marginLeft: ml },
    mr && { marginRight: mr },
    pt && { paddingTop: pt },
    pb && { paddingBottom: pb },
    pl && { paddingLeft: pl },
    pr && { paddingRight: pr },
    width && { width },
    size && { fontSize: size },
    transform && { textTransform: transform },
    underline && { textDecorationLine: 'underline' },
    align && { textAlign: align },
    height && { lineHeight: `${height}px` },
    spacing && { letterSpacing: spacing },
    weight && { fontWeight: weight },
    medium && styles.medium,
    bold && styles.bold,
    center && styles.center,
    right && styles.right,
    color &&  { color },
    style // rewrite predefined styles
  ];

  return (
    <span style={textStyles.reduce((prev = {}, curr) => {
      if (!curr) return prev;
      return { ...prev, ...curr };
    })} {...props}>
      {children}
    </span>
  );
}

const styles = {
  // default style
  text: {
    fontFamily: 'AvertaStd-Bold, sans-serif',
    fontSize: 14,
    lineHeight: 16,
    color: '#2E3747'
  },
  // position
  center: { textAlign: "center" },
  right: { textAlign: "right" }
};