import { gql, useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from 'styled-components';
import { store } from "../../../utils/store";
import { Loading } from "../../molecules";
import {
  Page404,
  Page500,
  PromoveTemplate,
  LoginTemplate,
} from "../../templates";
import DashboardLayout from '../DashboardLayout';
import ProtectedRoute from "./protected";


export default function Main() {

  const { tokenStore } = useContext(store);

  const [loading, setLoading] = useState(false);

  const [getSettings] = useLazyQuery(
    gql`
      query {
        languages: frontendLanguages{
          id
          name
          code
          default
        }
        navbar: frontendContentType(key: "navbar"){
          content
        }
        menu: frontendContentType(key: "menu"){
          content
        }
        medidataMundo: frontendContentType(key: "medidataMundoNum"){
          content
        }
        general: frontendContentType(key: "global"){
          content
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        // if (data?.languages && data?.languages?.length > 0) {
        //   setLanguages(data.languages);
        //   for (let i = 0; i < data.languages.length; i++) {
        //     if (data.languages[i].default === true) {
        //       setDefaultLanguage(data.languages[i].code);
        //     }
        //   }
        // }
        // if (data?.navbar?.content?.navbar) {
        //   setNavbar(data?.navbar?.content?.navbar);
        // }
        // if (data?.menu?.content?.sectionkey) {
        //   setMenu(data?.menu?.content?.sectionkey)
        // }
        // if (data?.general?.content?.sectionkey) {
        //   setGlobal(data?.general?.content?.sectionkey)
        // }
        // if (data?.medidataMundo?.content?.medidataMundo) {
        //   setMedidataMundo(data?.medidataMundo?.content?.medidataMundo)
        // }
        setLoading(false);
      }
    },

  );

  useEffect(() => {
    // getSettings();
    // setLoading(true);
  }, []);

  if (loading) {
    return (<Loading lottie={true} isLoading={loading} />)
  } else {
    return (
      <Wrapper>
        <Switch>
          <Route exact path="/404" component={Page404} />
          <Route exact path="/500" component={Page500} />
          <Route exact path="/promover" component={PromoveTemplate} />
          <Route exact path="/login" render={LoginTemplate} />
          <ProtectedRoute path="/dashboard" component={DashboardLayout} />
          <Redirect path="*" to="/dashboard" />
        </Switch>
      </Wrapper >
    )
  }

}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  flex: 1;
  min-height: 100vh;
`;