import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GlobalStyle from './styles/global';
import { StateProvider } from './utils/store';

function Init({ children }) {
  return (
    <>
      <GlobalStyle />
      <StateProvider>
        {children}
      </StateProvider>
    </>
  );
}

ReactDOM.render(<Init><App /></Init>, document.getElementById('root'));