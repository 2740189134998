import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components';
import { Container, H5, Text16, Text14, Text12, Button } from '../../atoms';
import { ModalForm, Navbar, StyledInput, SelectInput, CheckBox, FileInput, Loading, AreasInput } from '../../molecules';
import * as yup from 'yup';
import { Formik, FieldArray } from 'formik';
import validateFormSchema from '../../../utils/validateFormSchema';
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

export default function EventUpdate() {

    const submitButtonRef = useRef(null);

    const { id } = useParams();

    const history = useHistory();

    const [openModal, setOpenModal] = useState(false);
    const [indexSessao, setIndexSessao] = useState(undefined);
    const [areas, setAreas] = useState([]);
    const [places, setPlaces] = useState([]);
    const [event, setEvent] = useState(undefined);
    const [showOptions, setShowOptions] = useState(false);

    const [observations, setObservations] = useState("");

    const [statusEvent] = useState([
        { label: 'Draft', value: 'DRAFT' },
        { label: 'Publicado', value: 'PUBLISHED' },
        { label: 'Cancelado', value: 'CANCELED' },
        { label: 'Em revisão', value: 'IN_REVISION' },
        { label: 'Não aprovado', value: 'REJECTED' },
    ]);

    const [optionsStatus] = useState([
        { label: 'Publicado', value: 'PUBLISHED' },
        { label: 'Draft', value: 'DRAFT' },
        { label: 'Cancelado', value: 'CANCELED' },
    ]);

    const [queryOthers, { loading: loadingOthers }] = useLazyQuery(
        gql`
            query {
                areas(first: 1000) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
                places(first: 10000) {
                    edges {
                        node {
                            id
                            name
                        }
                        cursor
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.areas) {
                    setAreas(data.areas.edges.map((edge) => ({ value: edge.node.id, label: edge.node.name })));
                }
                if (data && data.places) {
                    setPlaces(data.places.edges.map((edge) => ({ value: edge.node.id, label: edge.node.name })));
                }
            }
        }
    );

    const [queryEvent, { loading: loadingEvent }] = useLazyQuery(
        gql`
            query($id: ID!) {
                promoterEvent(id: $id) {
                    id
                    name
                    thumbnail
                    duration
                    minTickerPrice
                    maxTickerPrice
                    minAge
                    aboutTitle
                    aboutDescription
                    status
                    areas {
                        id
                    }
                    places {
                        id
                        place {
                            id
                        }
                        isVirtual
                        isInPerson
                        ticketsUrls
                        date
                        time
                    }
                    featured
                    recommended
                    observations
                    site
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.promoterEvent) {
                    setObservations(data.promoterEvent.observations);
                    setEvent(
                        {
                            ...data.promoterEvent.name && { name: data.promoterEvent.name },
                            ...data.promoterEvent.thumbnail && { thumbnail: data.promoterEvent.thumbnail },
                            ...data.promoterEvent.duration && { duration: data.promoterEvent.duration },
                            ...data.promoterEvent.minTickerPrice && { minTickerPrice: data.promoterEvent.minTickerPrice },
                            ...data.promoterEvent.maxTickerPrice && { maxTickerPrice: data.promoterEvent.maxTickerPrice },
                            ...data.promoterEvent.minAge && { minAge: data.promoterEvent.minAge },
                            ...data.promoterEvent.aboutTitle && { aboutTitle: data.promoterEvent.aboutTitle },
                            ...data.promoterEvent.aboutDescription && { aboutDescription: data.promoterEvent.aboutDescription },
                            ...data.promoterEvent.status && { status: data.promoterEvent.status },
                            ...data.promoterEvent.featured && { featured: data.promoterEvent.featured },
                            ...data.promoterEvent.recommended && { recommended: data.promoterEvent.recommended },
                            ...data.promoterEvent.site && { site: data.promoterEvent.site },
                            areas: data.promoterEvent.areas.map((area) => area.id),
                            places: data.promoterEvent.places.map((place) => (
                                {
                                    place: place.place.id,
                                    isVirtual: place.isVirtual,
                                    isInPerson: place.isInPerson,
                                    ticketsUrls: place.ticketsUrls,
                                    date: place.date,
                                    time: place.time
                                }
                            ))
                        }
                    )
                }
            }
        }
    );

    const [mutationEventUpdate, { loading: loadingEventUpdate }] = useMutation(
        gql`
            mutation promoterEventUpdate($id: ID!, $input: InputPromoterEventUpdate!) {
                promoterEventUpdate(id: $id, input: $input) {
                    id
                    name
                    thumbnail
                    duration
                    minTickerPrice
                    maxTickerPrice
                    minAge
                    aboutTitle
                    aboutDescription
                    status
                    areas {
                        id
                        name
                    }
                    places {
                        id
                        event {
                            id
                        }
                        place {
                            id
                            name
                        }
                        isVirtual
                        isInPerson
                        ticketsUrls
                        date
                        time
                    }
                    featured
                    recommended
                    site
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                history.push({ pathname: "/dashboard", state: { reset: true } });
            }
        }
    );

    useEffect(() => {
        queryOthers();
    }, []);

    useEffect(() => {
        queryEvent({ variables: { id: id } });
    }, [id]);

    const renderStatus = (status) => {
        let index = statusEvent.findIndex((item) => item.value === status);
        if (index !== -1) {
            return (statusEvent[index].label)
        } else {
            return ('Draft')
        }
    }

    return (
        <>
            <Loading isLoading={loadingOthers || loadingEvent} />
            <Navbar saveForm={() => submitButtonRef.current.click()} status={event?.status} loading={loadingEventUpdate} />
            {event &&
                <ContainerScroll>
                    <Container lg>
                        <Formik
                            initialValues={(event && event.observations) ? delete event.observations : event}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validate={values => {
                                const errors = validateFormSchema(
                                    yup.object().shape({
                                        name: yup.string().required(),
                                        thumbnail: yup.mixed(),
                                        duration: yup.string().nullable(),
                                        minTickerPrice: yup.string().nullable(),
                                        maxTickerPrice: yup.string().nullable(),
                                        minAge: yup.string().nullable(),
                                        aboutTitle: yup.string().nullable(),
                                        aboutDescription: yup.string().nullable(),
                                        areas: yup.array(yup.number()).required().min(1).defined(),
                                        places: yup.array(
                                            yup.object().shape({
                                                place: yup.number().required(),
                                                isVirtual: yup.boolean().nullable(),
                                                isInPerson: yup.boolean().nullable(),
                                                date: yup.string().required(),
                                                time: yup.string().nullable(),
                                                ticketsUrls: yup.array(yup.object().shape({ name: yup.string().required(), url: yup.string().url().required() }))
                                            })
                                        )
                                    }),
                                    values
                                )

                                if (Object.keys(errors).length > 0) {
                                    toast.error("Erro, por favor confira o preenchimento do formulário")
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                mutationEventUpdate({ variables: { id: id, input: { ...values, thumbnail: (!!values.thumbnail && !!values.thumbnail.size) ? values.thumbnail : undefined, minTickerPrice: !!values.minTickerPrice ? values.minTickerPrice.toString().replace(",", ".") : null, maxTickerPrice: !!values.maxTickerPrice ? values.maxTickerPrice.toString().replace(",", ".") : null } } });
                            }}
                        >
                            {({
                                values,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => {
                                const errorKeys = Object.keys(errors);
                                return (
                                    <form onSubmit={handleSubmit} autoComplete="off">
                                        <H5 color='#2E3747' style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: 'Informação do evento' }} />
                                        <ContainerContent>
                                            <ContainerForm>
                                                <BoxForm>
                                                    <StyledInput label='Nome' placeholder='Nome do evento' name='name' width={480} type='text' onChange={handleChange} value={values.name} onBlur={handleBlur} error={errors.name} />
                                                    <DivRow marginTop={28}>
                                                        <FileInput name="thumbnail" label="Imagem" placeholder='Seleccione ficheiro' width={280} onChange={handleChange} onBlur={handleBlur} value={values.thumbnail} error={errors.thumbnail} />
                                                        <StyledInput label='Duração' placeholder='Tempo de duração' name='duration' width={280} type='text' style={{ marginLeft: 20 }} value={values.duration} onChange={handleChange} onBlur={handleBlur} error={errors.duration} />
                                                    </DivRow>
                                                    <DivRow marginTop={28}>
                                                        <StyledInput label='Preço dos bilhetes' placeholder='Preço mín.' name='minTickerPrice' width={180} type='number' value={values.minTickerPrice} onChange={handleChange} onBlur={handleBlur} error={errors.minTickerPrice} />
                                                        <StyledInput label='' placeholder='Preço máx.' name='maxTickerPrice' width={180} type='number' style={{ marginLeft: 20 }} value={values.maxTickerPrice} onChange={handleChange} onBlur={handleBlur} error={errors.maxTickerPrice} />
                                                        <StyledInput label='Idade' placeholder='Idade de acesso' name='minAge' width={180} type='text' style={{ marginLeft: 20 }} value={values.minAge} onChange={handleChange} onBlur={handleBlur} error={errors.minAge} />
                                                    </DivRow>
                                                    <DivRow marginTop={28}>
                                                        <StyledInput label='Título' placeholder='Título da descrição geral' name='aboutTitle' width={480} type='text' value={values.aboutTitle} onChange={handleChange} onBlur={handleBlur} error={errors.aboutTitle} />
                                                    </DivRow>
                                                    <DivRow marginTop={28}>
                                                        <StyledInput label='Descrição' placeholder='Descrição geral do evento ou artista' name='aboutDescription' width={580} type='textarea' value={values.aboutDescription} onChange={handleChange} onBlur={handleBlur} error={errors.aboutDescription} />
                                                    </DivRow>
                                                    <DivRow marginTop={28}>
                                                        <AreasInput name="areas" areas={areas} error={errors.areas} />
                                                    </DivRow>
                                                </BoxForm>
                                                <H5 color='#2E3747' style={{ marginTop: 44, marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: 'Sessões' }} />
                                                <FieldArray
                                                    name='places'
                                                    render={arrayHelpers => (
                                                        <>
                                                            <DivColumn>
                                                                {(values.places && values.places?.length > 0) && (
                                                                    values.places.map((item, index) => (
                                                                        <BoxForm key={index} style={{ marginBottom: 16, border: !!errorKeys.find((errorKey) => errorKey.includes(`places[${index}]`)) ? "1px solid red" : "none" }}>
                                                                            <DivRow className={index === indexSessao ? 'header show' : 'header hide'}>
                                                                                <Text16 color='#2E3747' dangerouslySetInnerHTML={{ __html: 'Sessão #' + (index < 10 ? '0' + (index + 1) : index) }} />
                                                                                <div style={{ display: "flex", flexFlow: "row" }}>
                                                                                    <img src='/static/images/delete.svg' style={{ cursor: "pointer", paddingRight: 20, borderRight: "1px solid #D8DCDF" }} onClick={() => arrayHelpers.remove(index)} />
                                                                                    <div>
                                                                                        <DivChevron style={{ paddingLeft: 20 }} onClick={() => index === indexSessao ? setIndexSessao(undefined) : setIndexSessao(index)}>
                                                                                            <img src='/static/images/edit.svg' />
                                                                                        </DivChevron>
                                                                                    </div>
                                                                                </div>
                                                                            </DivRow>
                                                                            <DivColumn style={{ marginTop: 20 }} className={index === indexSessao ? 'show' : 'hide'}>
                                                                                <DivRow>
                                                                                    <SelectInput name={`places[${index}].place`} label="Local" placeholder='Nome do local (ex: sala de espetáculo)' onChange={handleChange} onBlur={handleBlur} error={errors && errors[`places[${index}].place`]} options={places} />
                                                                                </DivRow>
                                                                                <DivRow marginTop={12}>
                                                                                    <CheckBox name={`places[${index}].isVirtual`} value={item.isVirtual} label="É virtual" onChange={handleChange} onBlur={handleBlur} error={errors.isVirtual} style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }} />
                                                                                    <CheckBox name={`places[${index}].isInPerson`} value={item.isInPerson} label="É ao vivo" onChange={handleChange} onBlur={handleBlur} error={errors.isInPerson} style={{ display: 'flex', flexFlow: 'row', alignItems: 'center', marginLeft: 16 }} />
                                                                                    <Text14 color='#778390' style={{ marginLeft: 24 }} dangerouslySetInnerHTML={{ __html: 'Não encontra o local?' }} />
                                                                                    <ClickModal onClick={() => setOpenModal(true)}>
                                                                                        <Text14 color='#778390' style={{ marginLeft: 4, textDecoration: 'underline' }} dangerouslySetInnerHTML={{ __html: 'Sugira aqui.' }} />
                                                                                    </ClickModal>
                                                                                </DivRow>
                                                                                <DivRow marginTop={28}>
                                                                                    <StyledInput type='date' width={280} name={`places[${index}].date`} label="Data" value={item.date} error={errors && errors[`places[${index}].date`]} onBlur={handleBlur} onChange={handleChange} style={{ marginRight: 20 }} />
                                                                                    <StyledInput type='time' width={280} name={`places[${index}].time`} label="Hora" value={item.time} error={errors && errors[`places[${index}].time`]} onBlur={handleBlur} onChange={handleChange} />
                                                                                </DivRow>
                                                                                <FieldArray
                                                                                    name={`places[${index}].ticketsUrls`}
                                                                                    render={arrayHelpersTicket => (
                                                                                        <>
                                                                                            <DivColumn style={{ marginTop: 28 }}>
                                                                                                <DivRow>
                                                                                                    <img src='/static/images/ticket.svg' style={{ marginRight: 4 }} />
                                                                                                    <Text14 color='#2E3747' dangerouslySetInnerHTML={{ __html: 'Venda de bilhetes' }} />
                                                                                                </DivRow>
                                                                                                {(values.places[index].ticketsUrls && values.places[index].ticketsUrls.length > 0) && (
                                                                                                    values.places[index].ticketsUrls.map((item, i) => (
                                                                                                        <DivColumnTickets key={i} className={i === 0 ? 'first' : ''}>
                                                                                                            <DivRowTickets>
                                                                                                                <Text14 color='#2E3747' dangerouslySetInnerHTML={{ __html: i < 10 ? '0' + (i + 1) : i }} style={{ marginRight: 16, marginTop: 24 }} />
                                                                                                                <StyledInput name={`places[${index}].ticketsUrls[${i}].name`} label="Name" placeholder='Nome da bilheteira' width={248} style={{ marginRight: 20 }} value={item.name} error={errors && errors[`places[${index}].ticketsUrls[${i}].name`]} onBlur={handleBlur} onChange={handleChange} />
                                                                                                                <StyledInput name={`places[${index}].ticketsUrls[${i}].url`} label="Url" placeholder='Url da bilheteira' width={248} value={item.url} error={errors && errors[`places[${index}].ticketsUrls[${i}].url`]} onBlur={handleBlur} onChange={handleChange} />
                                                                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", marginTop: 24 }}>
                                                                                                                    <img src='/static/images/delete.svg' style={{ cursor: "pointer" }} onClick={() => arrayHelpersTicket.remove(i)} />
                                                                                                                </div>
                                                                                                            </DivRowTickets>
                                                                                                        </DivColumnTickets>
                                                                                                    ))
                                                                                                )}
                                                                                            </DivColumn>
                                                                                            <ButtonAddTickets onClick={() => arrayHelpersTicket.push({})}>
                                                                                                <Text12 color='#fff' dangerouslySetInnerHTML={{ __html: 'Adicionar sessão' }} />
                                                                                            </ButtonAddTickets>
                                                                                        </>
                                                                                    )}
                                                                                />
                                                                            </DivColumn>
                                                                        </BoxForm>
                                                                    ))
                                                                )}
                                                                {(values.places === undefined || values.places?.length === 0) &&
                                                                    <BoxForm style={{ flexFlow: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                        <DivRow>
                                                                            <img src='/static/images/no_locations.svg' style={{ marginRight: 4 }} />
                                                                            <Text16 color='#778390' dangerouslySetInnerHTML={{ __html: 'Não têm nenhuma sessão associada ao evento' }} />
                                                                        </DivRow>
                                                                        <ButtonAddSessaos onClick={() => arrayHelpers.push({ isVirtual: false, isInPerson: false })}>
                                                                            <img src='/static/images/ticket2.svg' style={{ marginRight: 4 }} />
                                                                            <Text14 color='#fff' dangerouslySetInnerHTML={{ __html: 'Adicionar sessão' }} />
                                                                        </ButtonAddSessaos>
                                                                    </BoxForm>
                                                                }
                                                            </DivColumn>
                                                            {(values.places && values.places?.length > 0) && (
                                                                <ButtonAddSessaos width='100%' onClick={() => arrayHelpers.push({ isVirtual: false, isInPerson: false })}>
                                                                    <img src='/static/images/ticket2.svg' style={{ marginRight: 4 }} />
                                                                    <Text14 color='#fff' dangerouslySetInnerHTML={{ __html: 'Adicionar sessão' }} />
                                                                </ButtonAddSessaos>
                                                            )}
                                                        </>
                                                    )}
                                                />
                                                <HidenButton type='submit' ref={submitButtonRef} />
                                            </ContainerForm>
                                            <DivColumn>
                                                <BoxStatus>
                                                    <DivHeaderStatus>
                                                        <Text16 color='#2E3747' dangerouslySetInnerHTML={{ __html: 'Estado do evento' }} />
                                                        <ButtonStatus onClick={() => setShowOptions(!showOptions)} disabled={values.status === 'IN_REVISION' || values.status === 'REJECTED'}>
                                                            <Text12 color='#fff' height='12px' dangerouslySetInnerHTML={{ __html: 'Alterar' }} />
                                                        </ButtonStatus>
                                                    </DivHeaderStatus>
                                                    <DivContentStatus colorStatus={values.status}>
                                                        {renderStatus(values.status)}
                                                    </DivContentStatus>
                                                </BoxStatus>
                                                <BoxOptions show={showOptions}>
                                                    {optionsStatus.map((status, index) => (
                                                        <OptionStatus key={String(index)} colorStatus={status.value} onClick={() => ((values.status = status.value), setShowOptions(!showOptions))}>
                                                            {status.label}
                                                            {(status.value === values.status) &&
                                                                <img src='/static/images/check.svg' />
                                                            }
                                                        </OptionStatus>
                                                    ))}
                                                </BoxOptions>
                                                {values.status === 'REJECTED' &&
                                                    <BoxObservations>
                                                        <TopObservations>
                                                            <Text16 color='#2E3747' dangerouslySetInnerHTML={{ __html: 'Motivo' }} />
                                                        </TopObservations>
                                                        <BottomObservations>
                                                            <Text14 color='#778390' dangerouslySetInnerHTML={{ __html: observations }} />
                                                        </BottomObservations>
                                                    </BoxObservations>
                                                }
                                            </DivColumn>
                                        </ContainerContent>
                                    </form>
                                )
                            }}
                        </Formik>
                    </Container>
                </ContainerScroll>
            }
            <ModalForm isOpen={openModal} toggleModal={() => setOpenModal(false)} />
        </>
    )
}

const ContainerScroll = styled.div`
    padding: 40px 0;
    max-height: calc(100vh - 64px);
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
`;
const ContainerContent = styled.div`
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
`;
const ContainerForm = styled.div`
    display: flex;
    flex-flow: column;
`;
const BoxForm = styled.div`
    background: #FFFFFF;
    border-radius: 6px;
    padding: 16px 20px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    width: 620px;
    max-width: 100%;
`;
const DivRow = styled.div`
    display: inline-flex;
    margin-top: ${({ marginTop }) => marginTop ? marginTop : 0}px;
    &.header{
        justify-content: space-between;
        align-items: center;
    }
    &.show{
        padding-bottom: 16px;
        border-bottom: 1px solid #D8DCDF;
    }
    &.hide{
        
    }
`;
const DivChevron = styled.div`
    :hover{
        cursor: pointer;
    }
    /* img{
        transform: rotate(180deg);
    }
    ${({ rotate }) => rotate === 'true' && css`
        img{
            transform: rotate(0deg);
        }
    `}; */
`;
const DivColumn = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    transition: all 0.4s ease-in-out;
    &.show{
        display: flex;
    }
    &.hide{
        display: none;
    }
`;
const HidenButton = styled.button`
    height: 0;
    width: 0;
`;
const ButtonAddSessaos = styled.div`
    width: ${({ width }) => width ? width : '158px'};
    height: 48px;
    background: #0598FF;
    border-radius: 4px;
    display:flex;
    justify-content: center;
    align-items: center;
    :hover{
        cursor: pointer;
    }
`;
const DivColumnTickets = styled.div`
    display: flex;
    flex-flow: column;
    padding: 20px 16px;
    margin: 0 -16px;
    border-bottom: 1px solid #D8DCDF;
    &.first{
        border-top: 1px solid #D8DCDF;
        margin-top: 12px;
    }
`;
const DivRowTickets = styled.div`
    display: inline-flex;
    align-items: center;
`;
const ButtonAddTickets = styled.div`
    margin-top: 12px;
    width: 100%;
    height: 32px;
    background: #B4BCC8;
    border-radius: 4px;
    display:flex;
    justify-content: center;
    align-items: center;
    :hover{
        cursor: pointer;
    }
`;
const ClickModal = styled.div`
    :hover{
        cursor: pointer;
    }
`;
const BoxStatus = styled.div`
    position: sticky;
    top: 0;
    width: 300px;
    border-radius: 6px;
    background: #FFFFFF;
    overflow: hidden;
`;
const DivHeaderStatus = styled.div`
    padding: 16px 20px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`;
const DivContentStatus = styled.div`
    padding: 16px 20px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    font-family: AvertaStd-Semibold;
    font-size: 24px;
    line-height: 32px;
    ${({ colorStatus }) => colorStatus === 'DRAFT' && css`
        background-color: #FFBE28;
        color: #fff;
    `};
    ${({ colorStatus }) => colorStatus === 'PUBLISHED' && css`
        background-color: #2DCE7D;
        color: #fff;
    `};
    ${({ colorStatus }) => colorStatus === 'CANCELED' && css`
        background: #FF4343;
        color: #fff;
    `};
    ${({ colorStatus }) => colorStatus === 'IN_REVISION' && css`
        background-color: #FFBE28;
        color: #fff;
    `};
    ${({ colorStatus }) => colorStatus === 'REJECTED' && css`
        background: #FF4343;
        color: #fff;
    `};
`;
const ButtonStatus = styled.div`
    width: 80px;
    height: 32px;
    background: #B4BCC8;
    border-radius: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
    :hover{
        cursor: pointer;
        background: #778390;
    }
    ${({ disabled }) => disabled && css`
        background: #EEEEEE;
        div{
            color: #D8DCDF;
        }
        pointer-events: none;
    `};
`;
const BoxOptions = styled.div`
    height: 0;
    display: flex;
    flex-flow: column;
    border-radius: 6px;
    overflow: hidden;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    ${({ show }) => show && css`
        height: auto;
        opacity: 1;
    `};
`;
const OptionStatus = styled.div`
    padding: 16px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    font-family: AvertaStd-Semibold;
    font-size: 24px;
    line-height: 32px;
    ${({ colorStatus }) => colorStatus === 'DRAFT' && css`
        background-color: #FFBE28;
        color: #fff;
    `};
    ${({ colorStatus }) => colorStatus === 'PUBLISHED' && css`
        background-color: #2DCE7D;
        color: #fff;
    `};
    ${({ colorStatus }) => colorStatus === 'CANCELED' && css`
        background: #FF4343;
        color: #fff;
    `};
    ${({ colorStatus }) => colorStatus === 'IN_REVISION' && css`
        background-color: #FFBE28;
        color: #fff;
    `};
    ${({ colorStatus }) => colorStatus === 'REJECTED' && css`
        background: #FF4343;
        color: #fff;
    `};
    :hover{
        cursor: pointer;
    }
`;
const BoxObservations = styled.div`
    position: sticky;
    top: 148px;
    margin-top: 20px;
    width: 300px;
    background: #FFFFFF;
    border-radius: 6px;
    overflow: hidden;
    box-sizing:border-box;
`;
const TopObservations = styled.div`
    padding: 16px 20px;
    border-bottom: 1px solid #D8DCDF;
`;
const BottomObservations = styled.div`
    padding: 16px 20px;
    flex: 1;
`;