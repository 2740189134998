import { Field } from "formik";
import React from "react";
import styled, { css } from 'styled-components';
import Block from '../table/components/Block';
import Text from '../table/components/Text';

function AreasInput(
  {
    name,
    areas = [],
    error,
  }
) {
  return (
    <Field name={name}>
      {({ field: { value = [] }, form: { setFieldValue } }) => {
        return (
          <Block>
            <Text size={14} height={24} color={error ? "#FF3939" : "#2E3747"}>Áreas</Text>
            <Text size={14} height={24} color="#B4BCC8" mb={12}>Escolha das áreas do seu evento</Text>
            <Block ml={-4} mr={-4} row style={{ flexWrap: "wrap" }}>
              {areas.map((area) => {
                const selected = value.includes(area.value);
                return (
                  <Block
                    key={area.value}
                    pl={16}
                    pr={16}
                    pt={8}
                    pb={8}
                    ml={4}
                    mr={4}
                    mb={8}
                    br={4}
                    color={selected ? "#0598ff1a" : "#2E37471a"}
                    middle
                    pointer
                    style={{ border: selected ? "1px solid #0598FF" : "1px solid #2E37471a" }}
                    onClick={() => setFieldValue(name, selected ? value.filter((item) => item != area.value) : [...value, area.value])}
                  >
                    <Text size={11} height={16} color={selected ? "#0598FF" : "#B4BCC8"}>{area.label}</Text>
                  </Block>
                );
              })}
            </Block>
            <Text size={12} height={20} color={error ? "#FF3939" : "#2E3747"}>* obrigatório</Text>
          </Block>
        );
      }}
    </Field>
  );
}

export default React.memo(AreasInput);

const LabelCheck = styled.label`
  height: 12px;
  width: 12px;
`;
const InnerBox = styled.div`
  width: 8px;
  height: 8px;
  background: #0598FF;
  opacity: 0;
  transition: all 0.4s ease-in-out;
`;
const TextInput = styled(Text)`
  color: #B2B6B9;
  transition: all 0.4s ease-in-out;
`;
const StyledCheckbox = styled.div`
  background: #F5F5F5;
  border: 1px solid #B2B6B9;
  width:12px;
  height:12px;
  background:transparent;
  display:flex;
  align-items:center;
  justify-content: center;
  transition:all 0.4s ease-in-out;
  box-sizing: border-box;
  :hover{
    border-color: #35383B;
    cursor: pointer;
  }
  ${({ error }) => error && css`
    ${({ value }) => !value && css`
      border-color: #FF0505;
    `};
  `};
  ${({ value }) => value && css`
    border-color: #0598FF;
    ${InnerBox}{
      opacity: 1;
    }
    ${TextInput}{
      color: #0598FF;
    }
  `};
`;