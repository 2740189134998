import styled, { css } from 'styled-components';

const AntTitle = styled.div`
  font-family: ${({ font }) => font ? font : 'AvertaStd-Bold'} , sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-align: ${({ align }) => align ? align : 'left'};
  color: ${({ color }) => color ? color : '#0598FF'};
  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
`;

export default AntTitle;