import { Field } from "formik";
import React, { useRef } from "react";
import styled, { css } from 'styled-components';
import { Text14 } from "../../atoms";
import Block from '../table/components/Block';

const Component = ({ label, name, value, setFieldValue, error, width, placeholder }) => {
    const hiddenFileInput = useRef(null);

    return (
        <>
            {label &&
                <Title color='#2E3747' dangerouslySetInnerHTML={{ __html: label }} />
            }
            {!label &&
                <div style={{ marginTop: 24 }} />
            }
            <DivInputFile width={width} onClick={() => hiddenFileInput.current.click()}>
                <Input type='text' placeholder={placeholder} name={name} defaultValue={value ? value.name : ""} width={width} error={error} readOnly/>
                <input ref={hiddenFileInput} name={name} type="file" accept="image/*" id='hidden' onChange={(e) => {
                    if (e.target.files[0]) {
                        setFieldValue(name, e.target.files[0]);
                        hiddenFileInput.current.value = null;
                    }
                }} />
                <img src='/static/images/file.svg' />
            </DivInputFile>
        </>
    )
}

function FileInput(
    {
        label,
        name,
        error,
        width,
        placeholder
    }
) {
    return (
        <BlockInput>
            <Field name={name}>
                {({ field: { value }, form: { setFieldValue } }) => {
                    return (<Component
                        label={label}
                        name={name}
                        value={value}
                        setFieldValue={setFieldValue}
                        error={error}
                        width={width}
                        placeholder={placeholder}
                    />)
                }}
            </Field>
        </BlockInput>
    );
}

export default React.memo(FileInput);


const BlockInput = styled(Block)`
    position: relative;
`;
const Title = styled(Text14)`
    margin-bottom: 8px;
`;
const Input = styled.input`
    display: flex;
    align-items: center;
    height: 48px;
    width: ${({ width }) => width ? width + 'px' : '100%'};
    max-width: 100%;
    border: unset;
    background: #EEEEEE;
    padding: 8px 16px;
    border-radius: 4px;
    font-family: 'AvertaStd-Semibold';
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    color: #778390;
    transition: all 0.4s ease-in-out;
    ::placeholder{
        color: #B4BCC8;
    }
    ${({ error }) => error && css`
        border: 1px solid #FF0505;
        color: #FF0505;
    `}
`;
const DivInputFile = styled.div`
    position: relative;
    width: ${({ width }) => width ? width + 'px' : '100%'};
    max-width: 100%;
    display: inline-flex;
    ${Input}{
        pointer-events: none;
    }
    #hidden {
        width: 0;
        height: 0;
    }
    img{
        position: absolute;
        top: 14px;
        right: 16px;
        bottom: 14px;
        width: 20px;
        height: 20px;
        :hover{
            cursor: pointer;
        }
    }
`;