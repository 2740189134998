import { gql, useMutation } from "@apollo/client";
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Intro, Loading } from '../../molecules';
import { Section, Container, Wrapper } from '../../atoms';

export default function Promover() {

    const [inputs, setInputs] = useState({
        name: '',
        company: '',
        phone: '',
        email: ''
    })
    const [success, setSuccess] = useState(false);

    const handleInput = (event) => {
        event.persist();
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }))
    };

    const [promoterSignup, { loading: loadingSignup }] = useMutation(
        gql`
            mutation ($name: String!, $email: String!, $company: String!, $phone: String!) {
                frontendPromoterSignup(name: $name, email: $email, company: $company, phone: $phone){
                    id
                }
            }
        `,
        {
            onError: console.log,
            onCompleted: (data) => {
                if (data && data.frontendPromoterSignup) {
                    setSuccess(true);
                }
            }
        }
    );

    const submitForm = (e) => {
        e.preventDefault();
        promoterSignup({
            variables: {
                name: inputs.name,
                email: inputs.email,
                company: inputs.company,
                phone: inputs.phone
            }
        })
    }

    return (
        <Section>
            {!success &&
                <DivFloat>
                    {/* <ButtonBack href='#'>
                        <img src='/static/images/arrow_left.svg' />
                    </ButtonBack> */}
                    <Logo src='/static/images/logo.svg' />
                </DivFloat>
            }
            {success &&
                <DivFloat center>
                    <Logo src='/static/images/logo.svg' />
                </DivFloat>
            }
            <Container lg>
                <Wrapper both >
                    {!success &&
                        <DivContainer>
                            <Block>
                                <Image src='/static/images/registo.png' />
                            </Block>
                            <Block>
                                <Intro
                                    smallTitle='Promotores'
                                    titleSize="H5"
                                    title='Quero promover eventos!'
                                    titleColor="#2E3747"
                                    titleMaxWidth="330px"
                                    description='É promotor de eventos e pretende que os seus eventos sejam promovidos na nossa App? Preencha o formulário, a nossa equipa irá entrar em contacto consigo para certificarmos a identidade.'
                                    descriptionSize={14}
                                    descriptionMaxWidth="480px"
                                    descriptionColor="#B4BCC8"
                                    descriptionLineHeight={24}
                                />
                                <Form onSubmit={submitForm}>
                                    <Block margin={24} >
                                        <Label dangerouslySetInnerHTML={{ __html: 'Nome' }} />
                                        <Input name="name" type="text" placeholder='Nome completo' value={inputs.name} onChange={handleInput} required />
                                    </Block>
                                    <Block margin={24}>
                                        <Label dangerouslySetInnerHTML={{ __html: 'Empresa' }} />
                                        <Input name="company" type="text" placeholder='Empresa que faz a promoção' value={inputs.company} onChange={handleInput} required />
                                    </Block>
                                    <BlockRow >
                                        <Block>
                                            <Label dangerouslySetInnerHTML={{ __html: 'Contacto' }} />
                                            <Input name="phone" width={180} type="phone" placeholder='Telemóvel' value={inputs.phone} onChange={handleInput} required />
                                        </Block>
                                        <Block style={{ marginLeft: 20 }}>
                                            <Label dangerouslySetInnerHTML={{ __html: 'Email' }} />
                                            <Input name="email" width={280} type="email" placeholder='Email empresarial' value={inputs.email} onChange={handleInput} required />
                                        </Block>
                                    </BlockRow>
                                    <Button type="submit">
                                        {!loadingSignup &&
                                            <>Enviar</>
                                        }
                                        {loadingSignup &&
                                            <Loading small isLoading={loadingSignup} />
                                        }
                                    </Button>
                                </Form>
                                <Block style={{ marginTop: 20 }}>
                                    <LabelContact  dangerouslySetInnerHTML={{ __html: 'Para esclarecimentos <a href="mailto:geral@whatsupintown.com">geral@whatsupintown.com</a>' }} />
                                </Block>
                            </Block>
                        </DivContainer>
                    }
                    {success &&
                        <DivContainerSuccess>
                            <Image src='/static/images/registo.png' small />
                            <Intro
                                centerContainer={true}
                                titleSize="H5"
                                title='Submetido com sucesso!'
                                titleColor="#2E3747"
                                titleMaxWidth="356px"
                                titleTextAlign='center'
                                description='A nossa equipa vai rever toda a informação e após validação irá receber um email de confirmação. Caso não receba este email nos próximos dias, por favor verifique o seu spam.'
                                descriptionSize={14}
                                descriptionMaxWidth="420px"
                                descriptionColor="#B4BCC8"
                                descriptionLineHeight={24}
                                descriptionTextAlign='center'
                            />
                            <ButtonSuccess href='https://whatsupintown.com/' target='__blank'>
                                Continuar
                            </ButtonSuccess>
                        </DivContainerSuccess>
                    }
                </Wrapper>
            </Container>
        </Section >
    )
};

const DivFloat = styled.div`
    position: absolute;
    top: 32px;
    left: 32px;
    display: flex;
    align-items: center;
    transition: all 0.4s ease-in-out;
    ${({ center }) => center && css`
        left: unset;
        width: 100%;
        align-items: center;
        justify-content: center;
    `}
`;
const ButtonBack = styled.a`
    width: 40px;
    height: 40px;
    background: #0598FF;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    transition: all 0.4s ease-in-out;
    :hover{
        cursor: pointer;
    }
`;
const Logo = styled.img`
    max-height: 28px;
    object-fit: contain;
    transition: all 0.4s ease-in-out;
`;

const DivContainer = styled.div`
    display: flex;
    flex-flow: row;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
const DivContainerSuccess = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
const Image = styled.img`
    width: 400px;
    max-width: 100%;
    object-fit: contain;
    margin-right: 100px;
    ${({ small }) => small && css`
        width: 240px;
        margin: 0;
        margin-bottom: 32px;
    `}
`;
const Form = styled.form`
    margin-top: 32px;
`;
const BlockRow = styled.div`
    display: flex;
    flex-flow: row;
    margin-bottom: ${({ margin }) => margin ? margin : 0}px;
`;
const Block = styled.div`
    display: flex;
    flex-flow: column;
    margin-bottom: ${({ margin }) => margin ? margin : 0}px;
`;
const Label = styled.label`
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #2E3747;
    font-family: 'AvertaStd-Bold', sans-serif;
    margin-bottom: 8px;
`;
const Input = styled.input`
    border: unset;
    background: #EEEEEE;
    border-radius: 4px;
    padding: 8px 16px;
    height: 48px;
    width: ${({ width }) => width ? width : 380}px;
    max-width: 100%;
    box-sizing: border-box;
    font-family: 'AvertaStd-Semibold', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #2E3747;
    ::placeholder{
        color: #B4BCC8;
    }
`;
const Button = styled.button`
    position: relative;
    margin-top: 40px;
    border: 0;
    background: #0598FF;
    border-radius: 4px;
    width: 180px;
    height: 48px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    font-family: AvertaStd-Semibold, sans-serif;
    :hover{
        cursor: pointer;
    }
`;
const ButtonSuccess = styled.a`
    margin-top: 52px;
    border: 0;
    background: #0598FF;
    border-radius: 4px;
    width: 180px;
    height: 48px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: AvertaStd-Semibold, sans-serif;
    :hover{
        cursor: pointer;
    }
`;
const LabelContact = styled.div`
    font-family: AvertaStd-Semibold, sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #B4BCC8;
    a{
        text-decoration: none;
        color: #B4BCC8;
    }
`;