import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Text14 } from '../../atoms';
import { Loading } from '../../molecules';

export default function Navbar({ logout, saveForm, user, loading, status }) {
  const location = useLocation();
  const history = useHistory();

  const name = user ? user : '';

  return (
    <DivNav>
      <Logo src='/static/images/logo_white.svg' />
      {logout &&
        <DivTextButton>
          <Text14 color='#fff' font='AvertaStd-Bold' dangerouslySetInnerHTML={{ __html: name }} />
          <Button onClick={logout} marginLeft>
            <img src='/static/images/logout.svg' />
          </Button>
        </DivTextButton>
      }
      {!logout &&
        <DivTextButton>
          <ButtonSave onClick={saveForm}>
            {loading &&
              <Loading small isLoading={loading} />
            }
            {!loading &&
              <>
                {(status !== 'DRAFT' && status) &&
                  <>
                    {status !== 'REJECTED' &&
                      <img src='/static/images/save.svg' style={{ marginRight: 4 }} />
                    }
                    <Text14 color='#fff' font='AvertaStd-Bold' dangerouslySetInnerHTML={{ __html: 'Guardar' }} />
                  </>
                }
                {(status === 'DRAFT' || !status) &&
                  <Text14 color='#fff' font='AvertaStd-Bold' dangerouslySetInnerHTML={{ __html: 'Enviar para revisão' }} />
                }
              </>
            }
          </ButtonSave>
          <Button onClick={() => history.goBack()}>
            <img src='/static/images/close.svg' />
          </Button>
        </DivTextButton>
      }
    </DivNav>
  )
}

const DivNav = styled.div`
  display:flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding-left: 24px;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box; 
  background-color: #0598FF;
`;
const Logo = styled.img`
  height: 32px;
  max-height: 32px;
  object-fit: contain;
`;
const DivTextButton = styled.div`
  display:  flex;
  flex-flow: row;
  align-items: center;
`;
const Button = styled.div`
  width: 64px;
  height: 64px;
  background: #2E3747;
  margin-left: ${({ marginLeft }) => marginLeft ? 40 : 0}px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
  }
`;
const ButtonSave = styled.div`
  position: relative;
  width: 180px;
  height: 64px;
  background: #778390;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
  }
  &.disabled{
    pointer-events: none;
    div{
      color: #A7A7A7;
    }
  }
`;