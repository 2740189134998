import React, { forwardRef } from 'react';
import Block from '../Block';
import Text from '../Text';

function Row({ actionsWidth, rowIndex, row, columns, children }, actionsRef) {
    return (
        <Block>
            <Block row height={56} pl={24} br={6} color="white" center>
                {columns.map((column, columnIndex) => {
                    return (
                        <Block key={columnIndex} flex>
                            <Text size={14} height={20}>{row[column.key]}</Text>
                        </Block>
                    );
                })}
                <Block right width={actionsWidth} row ref={rowIndex === 0 ? actionsRef : undefined}>
                    {row.actions}
                </Block>
            </Block>
            {children}
            <Block width="100%" height={0} mb={8} />
        </Block>
    );
}

export default forwardRef(Row);