import React from 'react';
import styled from 'styled-components';
import Promover from '../organisms/promover';

function PromoveTemplate() {

  return (
    <DivColumn>
      <Promover />
    </DivColumn>
  )
} export default PromoveTemplate;

const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;