import { ApolloProvider } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Main } from './components/layout';
import client from './utils/client';
import { store } from './utils/store';

function App() {

  const { setTokenStore } = useContext(store);

  const [cookies] = useCookies(['token']);

  useEffect(() => {
    if (cookies && cookies.token) {
      setTokenStore(cookies.token);
    }
  }, [])

  return (
    <ApolloProvider client={client}>
      <CookiesProvider>
        <BrowserRouter>
          <ToastContainer />
          <Main />
        </BrowserRouter>
      </CookiesProvider>
    </ApolloProvider>
  );
}

export default App;
