import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { Loading } from '../../molecules';
import { Row, Block, Text } from './components';

function Table({ columns = [], rows = [], customActionsWidth = 0, next, hasMore = false, loading }) {
    const rowsRef = useRef(null);
    const actionsRef = useRef(null);
    const [actionsWidth, setActionsWidth] = useState(customActionsWidth);

    useEffect(() => {
        if (!customActionsWidth && actionsRef.current) {
            setActionsWidth(actionsRef.current.offsetWidth);
        }
    }, [columns, rows]);

    return (
        <BlockTable style={{ overflow: "hidden" }}>
            <Block row pl={24}>
                {columns.map((column, columnIndex) => {
                    return (
                        <Block key={columnIndex} flex>
                            <Text size={10} height={16}>{column.label}</Text>
                        </Block>
                    );
                })}
                <Block width={actionsWidth} />
            </Block>
            <BlockColumns ref={rowsRef} mt={8} mb={4} flex >
                {!loading &&
                    <InfiniteScroll
                        height="calc(100vh - 200px)"
                        dataLength={rows.length}
                        next={next}
                        hasMore={hasMore}
                    >
                        {rows.map((row, rowIndex) => {
                            return (
                                <Row ref={actionsRef} actionsWidth={actionsWidth} key={rowIndex} rowIndex={rowIndex} row={row} columns={columns} />
                            );
                        })}
                    </InfiniteScroll>
                }
                {loading &&
                    <Loading table isLoading={loading}/>
                }
            </BlockColumns>
        </BlockTable>
    );
}

export default Table;

const BlockTable = styled(Block)`
    margin-top: 24px;
    max-height: calc(100vh - 184px);
`;
const BlockColumns = styled(Block)`
    position: relative;
    display: block;
`;