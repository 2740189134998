import React from 'react'
import styled, { css } from 'styled-components';
import { Text14 } from '../../atoms';

export default function StyledInput({ label, placeholder, name, width, type, value, error, ...props }) {
    return (
        <BlockInput {...props} >
            {label &&
                <Title color='#2E3747' dangerouslySetInnerHTML={{ __html: label }} />
            }
            {!label &&
                <div style={{ marginTop: 24 }} />
            }
            {type === 'textarea' &&
                <Textarea type={type} placeholder={placeholder} name={name} onChange={props.onChange} value={value ? value : ''} width={width} error={error} />
            }
            {type !== 'textarea' &&
                <Input type={type} placeholder={placeholder} name={name} onChange={props.onChange} value={value ? value : ''} width={width} error={error} />
            }
        </BlockInput>
    )
};

const BlockInput = styled.div`
    display: flex;
    flex-flow: column;
    position: relative;
`;
const Title = styled(Text14)`
    margin-bottom: 8px;
`;
const Input = styled.input`
    display: flex;
    align-items: center;
    height: 48px;
    width: ${({ width }) => width ? width + 'px' : '100%'};
    max-width: 100%;
    border: unset;
    background: #EEEEEE;
    padding: 8px 16px;
    border-radius: 4px;
    font-family: 'AvertaStd-Semibold';
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    color: #778390;
    transition: all 0.4s ease-in-out;
    ::placeholder{
        color: #B4BCC8;
    }
    ${({ error }) => error && css`
        border: 1px solid #FF0505;
        color: #FF0505;
    `}
`;
const Textarea = styled.textarea`
    display: flex;
    align-items: center;
    min-height: 136px;
    width: ${({ width }) => width ? width + 'px' : '100%'};
    max-width: 100%;
    border: unset;
    background: #EEEEEE;
    padding: 8px 16px;
    border-radius: 4px;
    font-family: 'AvertaStd-Semibold';
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    color: #778390;
    transition: all 0.4s ease-in-out;
    ::placeholder{
        color: #B4BCC8;
    }
    ${({ error }) => error && css`
        border: 1px solid #FF0505;
        color: #FF0505;
    `}
`;
const DivInputFile = styled.div`
    position: relative;
    width: ${({ width }) => width ? width + 'px' : '100%'};
    max-width: 100%;
    display: inline-flex;
    ${Input}{
        pointer-events: none;
    }
    #hidden {
        width: 0;
        height: 0;
    }
    img{
        position: absolute;
        top: 14px;
        right: 16px;
        bottom: 14px;
        width: 20px;
        height: 20px;
        :hover{
            cursor: pointer;
        }
    }
`;
