import styled, { css } from 'styled-components';

const Text10 = styled.div`
font-family: ${({ font }) => font ? font : 'AvertaStd-Semibold'} , sans-serif;
  font-size: 10px;
  line-height:${({ height }) => height ? height : 20}px;
  text-align: ${({ align }) => align ? align : 'left'};
  color: ${({ color }) => color ? color : 'black'};
  column-count:${({ withCols }) => withCols ? 2 : 1};

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}

  b{
    font-family: 'AvertaStd-Bold', sans-serif;
  }
  
  strong{
    font-family: 'AvertaStd-Bold' , sans-serif;
  }

  @media screen and (max-width: 768px) {
    column-count:1;
  }

  a{
    color:#6F6F6E;
    :hover{
      cursor: pointer;
      color:#E3000F;
    }
  }
`;

export default Text10;