import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal, Text14 } from '../../atoms';
import { Loading, StyledInput } from '../../molecules';
import Intro from '../intros';

export default function ModalForm(props) {

  const {
    isOpen,
    toggleModal
  } = props;

  const [animation, setAnimation] = useState(false);
  const [description, setDescription] = useState('');

  const [successForm, setSuccessForm] = useState(false);

  const afterOpenModal = () => {
    setAnimation(true);
  };

  const afterCloseModal = () => {
    setAnimation(false);
    setDescription('');
    setSuccessForm(false);
  };

  const onRequestClose = () => {
    setAnimation(false);
  };

  useEffect(() => {
    if (isOpen === false) {
      onRequestClose();
    }
  }, [isOpen]);

  const [userSuggestion, { loading }] = useMutation(
    gql`
          mutation ($text: String!) {
            suggestedPlaceCreate(text: $text){
              id
            }
          }
      `,
    {
      onError: console.log,
      onCompleted: (data) => {
        if (data && data.suggestedPlaceCreate) {
          setSuccessForm(true);
        }
      }
    }
  );

  return (
    <Modal isOpen={isOpen} afterOpenModal={afterOpenModal} afterCloseModal={afterCloseModal}>
      <Overlay animation={animation} />
      <Content animation={animation}>
        <Structure>
          <ButtonClose onClick={toggleModal}>
            <img src='/static/images/close_grey.svg' />
          </ButtonClose>
          {successForm &&
            <>
              <Image src='/static/images/img_suggestion.png' />
              <Intro
                centerContainer={true}
                titleSize="H5"
                title='Sugestão enviada com sucesso!'
                titleColor="#2E3747"
                titleMaxWidth="420px"
                titleTextAlign='center'
                description='A nossa equipa vai rever toda a informação, e após confirmação positiva, o local será adicionado à listagem.'
                descriptionSize={14}
                descriptionMaxWidth="420px"
                descriptionColor="#B4BCC8"
                descriptionLineHeight={24}
                descriptionTextAlign='center'
              />
              <Margin margin={3} />
              <DivButton>
                <Button onClick={toggleModal}>
                  <Text14 color='#fff' dangerouslySetInnerHTML={{ __html: 'Continuar' }} />
                </Button>
              </DivButton>
            </>
          }
          {!successForm &&
            <>
              <Image src='/static/images/img_suggestion.png' />
              <Intro
                centerContainer={true}
                titleSize="H5"
                title='Sugestão de local'
                titleColor="#2E3747"
                titleMaxWidth="420px"
                titleTextAlign='center'
                description='Como não encontrou o local que procurava para o seu evento, escreva na caixa abaixo o local que sugere para adicionar à listagem, após revisão será adicionado.'
                descriptionSize={14}
                descriptionMaxWidth="420px"
                descriptionColor="#B4BCC8"
                descriptionLineHeight={24}
                descriptionTextAlign='center'
              />
              <Margin margin={3} />
              <StyledInput label='Local' placeholder='Descrição, nome e coordenadas' name='description' width={420} type='textarea' value={description} onChange={(e) => setDescription(e.target.value)} />
              <DivButton>
                <Button onClick={() => !loading ? userSuggestion({ variables: { text: description } }) : undefined}>
                  {!loading &&
                    <Text14 color='#fff' dangerouslySetInnerHTML={{ __html: 'Enviar sugestão' }} />
                  }
                  {loading &&
                    <Loading small isLoading={loading} />
                  }
                </Button>
              </DivButton>
            </>
          }
        </Structure>
      </Content>
    </Modal>
  )
}
const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`
const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    background-color: rgba(0,0,0,0.5);
  `}
`;
const Structure = styled.div`
  width: 620px;
  height: 684px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-flow:column;
  align-items: center;
  justify-content: center;
  background: #fff;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-sizing: border-box;
  transform: translateY(-100%);
`;
const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    opacity: 1;
    ${Structure} {
      transform: translateY(0%);
    }
  `};
  @media screen and (max-width: 1200px){
    width:100%;
  }
`;
const ButtonClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  img{
    height: 24px;
    width: 24px;
    object-fit: contain;
  }
  :hover{
    cursor: pointer;
  }
`;
const Image = styled.img`
  max-width: 200px;
  margin-bottom: 24px;
`;
const DivButton = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button = styled.div`
  position: relative;
  width: 180px;
  height: 48px;
  background: #0598FF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    cursor: pointer;
  }
`;