import React from 'react';
import { Dashboard } from '../organisms';

function DashboardTemplate() {

  return (
    <Dashboard />
  )
}
export default DashboardTemplate;
