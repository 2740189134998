import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../../../utils/store';

const ProtectedRoute = ({ component: Component, ...rest }) => {

    const { tokenStore } = useContext(store);

    const [cookies] = useCookies(['token']);

    return (
        <Route {...rest} render={
            props => {
                if (tokenStore || cookies.token) {
                    return <Component {...rest} {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: '/login'
                        }
                    } />
                }
            }
        } />
    )
}

export default ProtectedRoute;