import React, { createContext, useState } from 'react';

const store = createContext();
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [tokenStore, setTokenStore] = useState(undefined);
  const [userStore, setUserStore] = useState(undefined);
  return <Provider value={
    {
      tokenStore, setTokenStore,
      userStore, setUserStore
    }
  }>{children}</Provider>;
};

export { store, StateProvider };
