import { gql, useLazyQuery, useMutation } from '@apollo/client';
import React, { useRef, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Container, H5, Text14 } from '../../atoms';
import { Intro, ModalDelete, Navbar, Table, Loading } from '../../molecules';

function Dashboard() {

    const [search, setSearch] = useState(undefined);
    const [modalDeleteData, setModalDeleteData] = useState(undefined);

    const [cookies, removeCookie] = useCookies(['token', 'user']);
    const user = cookies.user;
    const history = useHistory();
    const paginate = useRef(false);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [queryEvents, { loading: loadingEvents }] = useLazyQuery(
        gql`
            query ($first: Int, $after: String, $search: String) {
                promoterEvents(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            thumbnail
                            duration
                            minTickerPrice
                            maxTickerPrice
                            minAge
                            aboutTitle
                            aboutDescription
                            status
                            areas {
                                id
                                name
                            }
                            places {
                                id
                                event {
                                    id
                                }
                                place {
                                    id
                                    name
                                }
                                isVirtual
                                isInPerson
                                ticketsUrls
                                date
                                time
                            }
                            featured
                            recommended
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: console.log,
            onCompleted: (data) => {
                if (data && data.promoterEvents) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.promoterEvents.edges] : data.promoterEvents.edges,
                        pageInfo: data.promoterEvents.pageInfo,
                        totalCount: data.promoterEvents.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [promoterEventDelete, { loading: loadingEventDelete }] = useMutation(
        gql`
            mutation promoterEventDelete($id: ID!) {
                promoterEventDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.promoterEventDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.promoterEventDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteData(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryEvents({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
    }, []);

    useEffect(() => {
        requestData();
    }, [search]);

    const logout = () => {
        removeCookie('token');
        removeCookie('user');
        history.push('/login');
    }

    return (
        <>
            <Navbar logout={() => logout()} user={user} />
            <WrapperContainer>
                <Container lg style={{ maxHeight: '100%'}}>
                    <ContainerDashboard>
                        <DivRow>
                            <H5 color='#2E3747' dangerouslySetInnerHTML={{ __html: 'Meus eventos' }} />
                            <DivSearchCreate>
                                <DivSearch>
                                    <InputSearch type='text' name='search' placeholder='Procurar eventos' value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <IconSearch src='/static/images/search.svg' />
                                </DivSearch>
                                <Link to='/dashboard/novo'>
                                    <ButtonCreate>
                                        <Text14 color='#fff' dangerouslySetInnerHTML={{ __html: 'Criar evento' }} />
                                    </ButtonCreate>
                                </Link>
                            </DivSearchCreate>
                        </DivRow>
                        <DivTable>
                            <Table
                                columns={[
                                    { label: "Nome", key: "name" },
                                    { label: "Status", key: "status" },
                                    { label: "Nº de sessões", key: "places" },
                                ]}
                                rows={result.edges.map((edge) => {
                                    return {
                                        name: edge.node.name,
                                        status: <>
                                            {edge.node.status === 'PUBLISHED' &&
                                                <>Publicado</>
                                            }
                                            {edge.node.status === 'REVISION' &&
                                                <>Em revisão</>
                                            }
                                            {edge.node.status === "REJECTED" &&
                                                <>Não aprovado</>
                                            }
                                            {edge.node.status === 'DRAFT' &&
                                                <>Draft</>
                                            }
                                            {edge.node.status === 'IN_REVISION' &&
                                                <>Em revisão</>
                                            }
                                        </>,
                                        places: edge.node.places?.length,
                                        actions: <>
                                            <Link to={`/dashboard/editar/${edge.node.id}`}>
                                                <ButtonActionTable >
                                                    <img src='/static/images/edit.svg' />
                                                    <Text14 color='#2E3747' dangerouslySetInnerHTML={{ __html: 'Editar' }} />
                                                </ButtonActionTable>
                                            </Link>
                                            <ButtonActionTable onClick={() => setModalDeleteData(edge.node)} >
                                                <img src='/static/images/delete.svg' />
                                            </ButtonActionTable>
                                        </>
                                    }
                                })}
                                next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                                hasMore={result.pageInfo.hasNextPage}
                            />
                        </DivTable>
                    </ContainerDashboard>
                </Container>
            </WrapperContainer>

            {modalDeleteData &&
                <ModalDelete isOpen={modalDeleteData ? true : false}>
                    <DivTopDelete>
                        <DivTitle>
                            <Text14 color='#fff' dangerouslySetInnerHTML={{ __html: 'Apagar Evento' }} />
                        </DivTitle>
                        <ButtonModal onClick={() => setModalDeleteData(undefined)}>
                            <Icon src="/static/images/close.svg" />
                        </ButtonModal>
                    </DivTopDelete>
                    <DivContentDelete>
                        <Intro
                            titleSize="H6"
                            title={`Tem certeza de que deseja apagar '${modalDeleteData?.name}'?`}
                            titleColor="#35383B"
                            titleMaxWidth="232px"
                            description='Esta ação é irreversível, irá apagar todos os dados.'
                            descriptionSize={12}
                            descriptionMaxWidth="193px"
                            descriptionColor="#5D6368"
                        />
                        <ButtonDeleteModal onClick={() => promoterEventDelete({ variables: { id: modalDeleteData?.id } })}>
                            {!loadingEventDelete &&
                                <Text14 color='#fff' dangerouslySetInnerHTML={{ __html: 'Apagar' }} />
                            }
                            {loadingEventDelete &&
                                <Loading small isLoading={loadingEventDelete} />
                            }
                        </ButtonDeleteModal>
                    </DivContentDelete>
                </ModalDelete>
            }
        </>
    )
}
export default Dashboard;

const WrapperContainer = styled.div`
    max-height: calc(100vh - 64px);
`;
const ContainerDashboard = styled.div`
    display:flex;
    flex-flow: column;
    padding: 32px 0;
    max-height: calc(100vh - 64px);
    box-sizing: border-box;
`;
const DivRow = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
`;
const DivSearchCreate = styled.div`
    display: inline-flex;
`;
const DivSearch = styled.div`
    position: relative;
    margin-right: 24px;
`;
const InputSearch = styled.input`
    padding: 8px 40px 8px 15px;
    width: 398px;
    height: 48px;
    border: 0;
    background: #D8DCDF;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: 'AvertaStd-Semibold',sans-serif;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #2E3747;
    ::placeholder{
        color: #778390;
    }
`;
const IconSearch = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
    bottom: 16px;
`;
const ButtonCreate = styled.div`
  width: 158px;
  height: 48px;
  background: #0598FF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    cursor: pointer;
  }
`;
const DivTable = styled.div`
    position: relative;
    flex: 1;
`;
const ButtonModal = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    background: #2E3747;
    height: 56px;
    width: 56px;
    transition: all 0.4s ease-in-out;
    z-index: 1;
    :hover{
        cursor: pointer;
    }
`;
const Icon = styled.img`
    height: 16px;
    width: 16px;
    max-height: 16px;
    max-width: 16px;
    object-fit: contain;
`;
const ButtonActionTable = styled.div`
    height: 56px;
    padding: 16px 24px;
    border-left: 1px solid #D8DCDF;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    :hover{
        cursor: pointer;
    }
`;
const DivTopDelete = styled.div`
    width: 100%;
    display: inline-flex;
    height: 56px;
`;
const DivTitle = styled.div`
    background: #0598FF;
    display: inline-flex;
    align-items: center;
    flex: 1;
    padding-left: 16px;
`;
const DivContentDelete = styled.div`
    padding: 24px;
    display:flex;
    flex-flow: column;
`;
const ButtonDeleteModal = styled.div`
    position: relative;
    margin-top: 24px;
    width: 176px;
    height: 56px;
    display: inline-flex;
    align-items: center;
    background: #2E3747;
    padding: 0 16px;
    box-sizing: border-box;
    :hover{
        cursor: pointer;
    }
`;