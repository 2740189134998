import { gql, useMutation } from "@apollo/client";
import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { Intro, Loading } from '../../molecules';
import { Section, Container, Wrapper } from '../../atoms';
import ReactCodeInput from 'react-code-input'
import { store } from '../../../utils/store';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { setToken } from "../../../utils/token";

export default function Login() {

    const { setTokenStore, setUserStore } = useContext(store);
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['token', 'user']);

    const [email, setEmail] = useState(undefined);
    const [code, setCode] = useState(undefined);

    const [successEmail, setSuccessEmail] = useState(false);
    const [successCode, setSuccessCode] = useState(false);

    const [userEmail, { loading: loadingEmail }] = useMutation(
        gql`
            mutation ($email: String!) {
                userRequestCode(email: $email)
            }
        `,
        {
            onError: console.log,
            onCompleted: (data) => {
                if (data && data.userRequestCode) {
                    setSuccessEmail(true);
                }
            }
        }
    );

    const [userCode, { loading: loadingCode }] = useMutation(
        gql`
            mutation ($email: String!, $code: String!) {
                userLogin(email: $email, code: $code){
                    user{
                        id
                        name
                        role
                    }
                    token
                }
            }
        `,
        {
            onError: () => alert("Código errado"),
            onCompleted: (data) => {
                if (data && data.userLogin) {
                    if (data.userLogin.user.role === 'role.promoter') {
                        setTokenStore(data.userLogin.token);
                        setToken(data.userLogin.token);
                        setCookie('token', data.userLogin.token);
                        setCookie('user', data.userLogin.user.name);
                        setUserStore(data.userLogin.user);
                        history.push('/dashboard');
                        setSuccessCode(true);
                    }
                }
            }
        }
    );

    const loginEmail = () => {
        userEmail({ variables: { email: email } })
    }

    const loginCode = () => {
        userCode({ variables: { email: email, code: code } })
    }

    return (
        <Section>
            <DivFloat>
                {/* <ButtonBack onClick={() => history.goBack()}>
                    <img src='/static/images/arrow_left.svg' />
                </ButtonBack> */}
                <Logo src='/static/images/logo.svg' />
            </DivFloat>
            <Container lg>
                <Wrapper both >
                    <DivContainerSuccess>
                        <Image src='/static/images/login.png' small />
                        <Intro
                            centerContainer={true}
                            titleSize="H5"
                            title='Área Promotor'
                            titleColor="#2E3747"
                            titleMaxWidth="356px"
                            titleTextAlign='center'
                            description='Introduza o email que nos foi facultado para a criação e certificação da sua conta'
                            descriptionSize={14}
                            descriptionMaxWidth="380px"
                            descriptionColor="#B4BCC8"
                            descriptionLineHeight={24}
                            descriptionTextAlign='center'
                        />
                        {!successEmail &&
                            <Block style={{ alignItems: 'center' }}>
                                <Block margin={40}>
                                    <Label dangerouslySetInnerHTML={{ __html: 'Email' }} />
                                    <Input name="name" type="email" placeholder='Email de registo' value={email} onChange={(e) => setEmail(e.target.value)} required />
                                </Block>
                                <ButtonSuccess onClick={() => loginEmail()}>
                                    {loadingEmail &&
                                        <Loading small isLoading={loadingEmail} />
                                    }
                                    {!loadingEmail &&
                                        <>
                                            Continuar
                                        </>
                                    }
                                </ButtonSuccess>
                            </Block>
                        }
                        {(successEmail && !successCode) &&
                            <Block style={{ alignItems: 'center' }}>
                                <Block margin={40}>
                                    <Label dangerouslySetInnerHTML={{ __html: 'Código de verificação' }} />
                                    <CodeInput type='password' fields={6} onChange={setCode} />
                                </Block>
                                <Block margin={16} style={{ alignItems: 'center' }}>
                                    <Button onClick={() => { loginEmail(); alert("Novo código enviado") }}>Reenviar código</Button>
                                </Block>
                                <ButtonSuccess onClick={() => loginCode()}>
                                    {loadingCode &&
                                        <Loading small isLoading={loadingCode} />
                                    }
                                    {!loadingCode &&
                                        <>
                                            Entrar
                                        </>
                                    }
                                </ButtonSuccess>
                            </Block>
                        }
                    </DivContainerSuccess>
                </Wrapper>
            </Container>
        </Section >
    )
};

const DivFloat = styled.div`
    position: absolute;
    top: 32px;
    left: 32px;
    display: flex;
    align-items: center;
    transition: all 0.4s ease-in-out;
    z-index: 1;
`;
const ButtonBack = styled.div`
    width: 40px;
    height: 40px;
    background: #0598FF;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    transition: all 0.4s ease-in-out;
    :hover{
        cursor: pointer;
    }
`;
const Logo = styled.img`
    max-height: 28px;
    object-fit: contain;
    transition: all 0.4s ease-in-out;
`;

const DivContainer = styled.div`
    display: flex;
    flex-flow: row;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
const DivContainerSuccess = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
const Image = styled.img`
    width: 400px;
    max-width: 100%;
    object-fit: contain;
    margin-right: 100px;
    ${({ small }) => small && css`
        width: 240px;
        margin: 0;
        margin-bottom: 32px;
    `}
`;
const Form = styled.form`
    margin-top: 32px;
`;
const BlockRow = styled.div`
    display: flex;
    flex-flow: row;
    margin-top: ${({ margin }) => margin ? margin : 0}px;
`;
const Block = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: ${({ margin }) => margin ? margin : 0}px;
`;
const Label = styled.label`
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #2E3747;
    font-family: 'AvertaStd-Bold', sans-serif;
    margin-bottom: 8px;
`;
const Input = styled.input`
    border: unset;
    background: #EEEEEE;
    border-radius: 4px;
    padding: 8px 16px;
    height: 48px;
    width: ${({ width }) => width ? width : 380}px;
    max-width: 100%;
    box-sizing: border-box;
    font-family: 'AvertaStd-Semibold', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #2E3747;
    ::placeholder{
        color: #B4BCC8;
    }
`;
const CodeInput = styled(ReactCodeInput)`
    display: inline-flex !important;
    margin-right: -7px;
    input{
        width: 46px;
        height: 48px;
        left: 32px;
        top: 136px;
        border: 0;
        background: #EEEEEE;
        border-radius: 4px;
        margin:0;
        padding: 0;
        margin-right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px
    }
`;
const Button = styled.div`
    width: 150px;
    height: 32px;
    background: #B4BCC8;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover{
        cursor: pointer;
    }
`;
const ButtonSuccess = styled.div`
    position: relative;
    margin-top: 52px;
    border: 0;
    background: #0598FF;
    border-radius: 4px;
    width: 180px;
    height: 48px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover{
        cursor: pointer;
    }
`;